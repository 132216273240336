.curriculumMain {
  background-color: #fdf2e9;
  min-height: 75vh;
  display: grid;
  grid-template-columns: 55fr 45fr;
  align-items: flex-start;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 35px;
}

.curriculumContent {
  padding: 15px;
  margin-bottom: 50px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 16px;
}
.galleryItem {
  overflow: hidden;
}
.galleryItem img {
  display: block;
  width: 100%;
  height: 22.5vh;
  transition: all 0.4s;
  object-fit: cover;
}
.galleryItem img:hover {
  transform: scale(1.1);
}
.timetable {
  min-height: 75vh;
  background-color: #fdf2e9;
  padding-top: 115px;
}
.arrow {
  height: auto;
  position: relative;
  width: auto;
}

.arrow::before {
  animation: bounce 1.5s ease infinite;
  bottom: 1rem;
  color: rgba(9, 9, 121, 1);
  content: "╲╱";
  font-size: 1rem;
  height: 4rem;
  left: 50%;
  letter-spacing: -1px;
  line-height: 4rem;
  margin-left: -3rem;
  opacity: 1;
  position: absolute;
  text-align: center;
  width: 6rem;
}

@keyframes bounce {
  50% {
    transform: translateY(-50%);
  }
}
.linkScroll {
  cursor: pointer;
}
@media (max-width: 1450px) {
  .arrow::before {
    bottom: 1px;
  }
}

@media (max-width: 1150px) {
  .curriculumMain {
    grid-template-columns: 45fr 55fr;
    font-size: 14px;
  }
  .curriculumContent {
    padding: 10px;
  }
  .arrow::before {
    bottom: -20px;
  }
}

@media (max-width: 1000px) {
  .curriculumMain {
    line-height: 30px;
  }
}

@media (max-width: 900px) {
  .curriculumMain {
    line-height: 24px;
    align-items: flex-start;
  }
}

@media (max-width: 800px) {
  .curriculumMain {
    display: flex;
    flex-direction: column;
    line-height: 30px;
    justify-content: center;
    align-items: center;
  }
  .curriculumContent {
    margin-bottom: 0px;
  }
}

@media (max-width: 750px) {
  .curriculumMain {
    min-height: 82vh;
    display: flex;
    flex-direction: column;
    line-height: 30px;
  }
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
  .arrow {
    display: none;
  }
}

@media (max-width: 500px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery div:last-child {
    display: none;
  }
}
