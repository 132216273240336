.main {
  min-height: 100vh;
}

.content {
  min-height: 75vh;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .content {
    min-height: 82vh;
  }
}
