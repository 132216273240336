.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  color: #fffffff0;
  max-width: 100vw;
  background: linear-gradient(90deg, #0abab5 0%, rgba(9, 9, 121, 1) 100%);
  box-shadow: 10px -5px 5px #eae7e7d4;
  position: sticky;
  bottom: 0%;
  z-index: 100;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.iconWechat {
  width: 2vw;
  margin-right: 10px;
  filter: invert();
  position: relative;
}

.future_div {
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 0px;
  padding: 1px;
  box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.714);
  cursor: pointer;
}

.future_div:hover {
  scale: 1.1;
}

.future {
  width: 3.5vw;
}

.spans {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.click {
  width: 3vw;
}

.iconBook {
  width: 5vw;
  margin-right: 10px;
  filter: invert();
}

.iconTikTok {
  width: 1.6vw;
  margin-right: 10px;
  filter: invert();
}

.iconWechat:hover {
  scale: 1.1;
}

.iconTikTok:hover {
  scale: 1.1;
}

.iconBook:hover {
  scale: 1.1;
}

.years {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}

.logo_footer {
  height: 8vh;
  width: 10vw;
  margin-left: 10px;
}

.qr2 {
  margin-left: 5px;
  width: 150px;
}
.qr1 {
  width: 150px;
}

.qrCode {
  display: flex;
  position: absolute;
  top: -250%;
  left: 75%;
}
@media only screen and (max-width: 1300px) {
  .qrCode {
    left: 65%;
  }
}

@media only screen and (max-width: 1050px) {
  .future_div {
    width: 35%;
  }
}
@media only screen and (max-width: 920px) {
  .icons {
    margin-right: 0px;
  }

  .future {
    width: 4vw;
  }

  .iconWechat {
    width: 3.5vw;
  }

  .iconBook {
    width: 10vw;
  }

  .iconTikTok {
    width: 3vw;
  }

  .qrCode {
    left: 55%;
  }
}

@media only screen and (max-width: 850px) {
  .future_div {
    width: 40%;
  }
  .future {
    width: 4.5vw;
  }
  .years {
    font-size: 14px;
  }
  .spans {
    font-size: 14px;
  }
}
@media only screen and (max-width: 700px) {
  .qrCode {
    left: 40%;
  }
  .future_div {
    width: 45%;
  }
}

@media only screen and (max-width: 600px) {
  .future_div {
    width: 55%;
  }
  .age {
    display: none;
  }
  .footer {
    justify-content: space-between;
  }

  .future {
    width: 10vw;
  }
  .click {
    width: 8vw;
  }

  .icons {
    margin-right: 0px;
  }

  .years {
    display: none;
  }

  .iconWechat {
    width: 7vw;
  }

  .iconBook {
    width: 20vw;
  }

  .iconTikTok {
    width: 6vw;
  }

  .qrCode {
    left: 15%;
  }
}
