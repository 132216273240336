.flag {
  width: 25px;
}

.mainDiv {
  position: relative;
}

.button {
  width: 35px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 12px;
  font-weight: 500;
  color: #fffffff0;
  background-color: rgb(56, 56, 239);
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.button:hover {
  scale: 1.1;
}

.languageMenu {
  width: 35px;
  position: absolute;
  right: 0;
  margin-top: 8px;
  border-radius: 4px;
}

.languageButton {
  font-size: 12px;
  width: 35px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  color: #4a5568;
  border: none;
}

.languageButton:hover {
  background-color: #0abab5;
  color: #fffffff0;
}
@media only screen and (max-width: 920px) {
  .languageMenu {
    width: 35px;
    position: absolute;
    left: 0;
    margin-top: 8px;
    border-radius: 4px;
  }
}
