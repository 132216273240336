/* Timetable.module.css */
.timeCardIcon {
  object-fit: contain;
  width: 60%;
  height: 60%;
  align-self: center;
}

.iconDiv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}
