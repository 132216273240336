@font-face {
  font-family: "KGPrimaryDotsLined";
  src: url("./KGPrimaryDotsLined.ttf") format("truetype");
}
@font-face {
  font-family: "print_clearly_tt";
  src: url("./print_clearly_tt.ttf") format("truetype");
}
@font-face {
  font-family: "print_dashed_tt";
  src: url("./print_dashed_tt.ttf") format("truetype");
}

.main {
  display: flex;
  height: 75vh;
  width: 100vw;
  overflow: hidden;
  justify-content: center;
}

.textContainer {
  /* flex: 1; */
  padding: 10px;
  border: 1px solid #ccc;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: none;
}

.textarea {
  margin-top: 40px;
  font-size: 72px;
  width: 95%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: center;
  white-space: break-spaces;
  word-wrap: break-word;
  /* overflow-wrap: break-word; */
}

.radioButtons {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.radioDotted {
  font-family: "KGPrimaryDotsLined", sans-serif;
  font-size: 82px;
  margin-left: 30px;
}
.radioNormal {
  font-family: "print_clearly_tt", sans-serif;
  font-size: 82px;
}
.radioDashed {
  font-family: "print_dashed_tt", sans-serif;
  font-size: 82px;
}
.inputTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputDiv {
  margin-left: 30px;
  text-align: center;
}
.previewContainer {
  /* flex: 1; */
  width: 700px;
  /* max-width: 100%; */
  padding: 10px;
  overflow-y: auto;
  border: none;
}

.preview {
  position: relative;
  border: 1px solid #ccc;

  padding: 30px;
  white-space: pre-wrap;
  word-wrap: break-word;
  /* overflow-wrap: break-word; */
  text-align: center;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.title {
  font-size: 36px;
  font-weight: 700;
}
.name {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 700;
}

.logo {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.text {
  margin-top: 60px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
  /* align-self: flex-start;
  justify-content: flex-start; */
  overflow-wrap: break-word;
  font-size: 72px;
  width: 100%;
  word-wrap: break-word;
  white-space: break-spaces;
  /* word-break: break-all; */
}

.downloadButton {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
.downloadButtonBlank {
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.downloadButton:hover {
  background-color: #0056b3;
}

.downloadButtonBlank:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 1390px) {
  .radioDotted {
    font-size: 62px;
  }
  .radioNormal {
    font-size: 62px;
  }
  .radioDashed {
    font-size: 62px;
  }
}

@media only screen and (max-width: 1240px) {
  .radioDotted {
    font-size: 42px;
    margin-left: 10px;
  }
  .radioNormal {
    font-size: 42px;
  }
  .radioDashed {
    font-size: 42px;
  }
  .textarea {
    font-size: 62px;
  }
}

@media only screen and (max-width: 1150px) {
  .text {
    font-size: 62px;
  }
}
@media only screen and (max-width: 1060px) {
  .textarea {
    font-size: 52px;
  }
  .radioButtons {
    flex-direction: column;
  }
}

@media only screen and (max-width: 925px) {
  .main {
    min-height: 82vh;
    flex-direction: column;
    justify-content: center;
  }
  .previewContainer {
    align-self: center;
    width: 600px;
    border: none;
  }
  .textContainer {
    border-top: none;
  }
}

@media only screen and (max-width: 600px) {
  .downloadButton {
    font-size: 10px;
  }
  .downloadButtonBlank {
    font-size: 10px;
  }
  .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .previewContainer {
    width: 500px;
  }
  .text {
    font-size: 52px;
  }
  .title {
    font-size: 30px;
  }
  .name {
    font-size: 12px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 600px) {
  .downloadButton {
    font-size: 10px;
  }
  .downloadButtonBlank {
    font-size: 10px;
  }
  .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .previewContainer {
    width: 350px;
  }
  .text {
    font-size: 32px;
  }
  .title {
    font-size: 20px;
  }
  .name {
    font-size: 10px;
  }

  .logo {
    width: 70px;
    height: 70px;
  }
}
