.navbar {
  display: flex;
  height: 17vh;
  justify-content: space-between;
  background: linear-gradient(
    90deg,
    #0abab5 0%,
    rgba(9, 9, 121, 1) 100% /* rgba(0, 212, 255, 1) 100% */
  );
  color: #fffffff0;
  align-items: center;
  box-shadow: 10px 5px 5px #eae7e7d4;
  position: sticky;
  top: 0%;
  z-index: 100;
}

.logoDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 110px;
  height: 110px;
  margin-left: 5vw;
}

.logoWord {
  margin-left: 20px;
  width: 15vw;
}

.logo:hover {
  scale: 1.1;
}

.navLinks {
  display: flex;
  width: 55vw;
  justify-content: space-between;
  margin-right: 5vw;
  font-size: 18px;
  position: relative;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.link {
  color: #fffffff0;
  text-decoration: none;
  transition: all 0.5s ease-in;
  font-family: "Montserrat", sans-serif;
}

.link:hover {
  color: rgb(236, 236, 26);
  transition: all 0.5s ease-in-out;
}

.dropdown {
  color: rgb(236, 236, 26);
}

:global(.ant-dropdown-menu) {
  margin-top: 10px !important;
  width: 200px;
  text-align: center;
  font-family: "Montserrat", sans-serif !important;
  border-radius: none !important;
}
:global(.ant-dropdown-menu-item:hover) {
  color: #fffffff0 !important;
  background: linear-gradient(90deg, #0abab5 0%, rgba(9, 9, 121, 1) 100%);
}
.languageDropDown {
  position: absolute;
  top: 2.5%;
  right: 0.3%;
}
.navbarLinksMobile {
  display: none;
}

@media only screen and (min-width: 100px) and (max-width: 920px) {
  .languageDropDown {
    position: absolute;
    top: 3.8%;
    left: 0.5%;
  }

  .menuIconWrapper {
    position: absolute;
    top: 0.5%;
    right: 1.5%;
    cursor: pointer;
    z-index: 9999;
  }
  .logo {
    display: none;
  }

  .logoWord {
    width: 30vw;
  }

  .navLinks {
    display: none;
  }
  .navLinksChinese {
    display: none;
  }

  .navbar {
    justify-content: center;
    height: 10vh;
  }
  .navbarLinksMobile {
    display: flex;
    align-items: center;
    top: 0.5%;
    right: 1.5%;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 3px;
  }

  .menuMobile {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .navLinksMobile {
    background: linear-gradient(
      90deg,
      #0abab4de 0%,
      rgba(9, 9, 121, 0.882) 100% /* rgba(0, 212, 255, 1) 100% */
    );
    border-radius: 5px;
    padding: 15px 5px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 30vh;
    margin-top: 40px;
    top: 13%;
    right: 3%;
    cursor: pointer;
    width: 32vw;
  }
  .navLinksMobileHidden {
    display: none;
  }

  :global(.ant-dropdown-menu) {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    position: absolute !important;
    width: 40vw;
    right: 125%;
    overflow-wrap: break-word;
    background: linear-gradient(
      90deg,
      #0abab5 0%,
      rgba(9, 9, 121, 1) 100% /* rgba(0, 212, 255, 1) 100% */
    );
  }

  :global(.ant-dropdown-menu-item) {
    position: relative;
    color: #fffffff0 !important;
    background: linear-gradient(
      90deg,
      #0abab5 0%,
      rgba(9, 9, 121, 1) 100% /* rgba(0, 212, 255, 1) 100% */
    );
  }

  :global(.ant-dropdown-menu-item:hover) {
    scale: 1.1;
  }
  :global(.ant-dropdown) {
    position: fixed;
    top: 10vh !important;
    left: 70vw !important;
    font-family: "Montserrat", sans-serif !important;
  }
}

@media only screen and (min-width: 921px) and (max-width: 1200px) {
  .link {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1700px) {
  .link {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 2500px) {
  .link {
    font-size: 26px;
  }
}

@media only screen and (min-width: 2501px) and (max-width: 3600px) {
  .link {
    font-size: 30px;
  }
}

@media only screen and (min-width: 3600px) and (max-width: 4500px) {
  .link {
    font-size: 40px;
  }
}

@media only screen and (max-width: 950px) {
  .logoWord {
    width: 24vw;
  }
}

@media only screen and (max-width: 700px) {
  .logoWord {
    width: 34vw;
  }
}
@media only screen and (max-width: 500px) {
  .logoWord {
    width: 38vw;
  }
}
@media only screen and (max-width: 400px) {
  .logoWord {
    width: 46vw;
  }
}
